<template>
  <v-expand-transition>
    <v-card
      v-show="!isRemoved"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title"></slot>
        
      </v-card-title>
      
      <v-card-subtitle>
        <slot name="subtitle"></slot>
        
      </v-card-subtitle>

      <v-expand-transition>
        <div v-show="!isContentCollapsed">
          <slot></slot>
        </div>
      </v-expand-transition>

      <v-overlay
        v-model="showOverlay"
        absolute
        color="white"
        opacity="0.75"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-expand-transition>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiRefresh, mdiChevronUp, mdiClose } from '@mdi/js'

export default {
  inheritAttrs: false,
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    actionCollapse: {
      type: Boolean,
      default: false,
    },
    actionRefresh: {
      type: Boolean,
      default: false,
    },
    actionRemove: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const showOverlay = ref(false)
    const isContentCollapsed = ref(props.collapsed)
    const isRemoved = ref(false)

    // Action Utils
    const hideOverlay = () => {
      showOverlay.value = false
    }

    const displayOverlay = () => {
      showOverlay.value = true
    }

    // Action Triggers
    const triggerCollapse = () => {
      isContentCollapsed.value = !isContentCollapsed.value
      emit('collapsed', isContentCollapsed.value)
    }
    const triggerRefresh = () => {
      displayOverlay()
      emit('refresh', hideOverlay)
    }
    const triggerRemove = () => {
      isRemoved.value = true
      emit('removed')
    }

    watch(
      () => props.refresh,
      (to, from) => {
        if(to){
          displayOverlay()
        } else {
          hideOverlay()
        }
      }
    )

    return {
      showOverlay,
      isContentCollapsed,
      isRemoved,

      // Triggerer
      triggerRefresh,
      triggerCollapse,
      triggerRemove,

      // Icons
      icons: {
        mdiChevronUp,
        mdiRefresh,
        mdiClose,
      },
    }
  },
}
</script>

<style>
</style>
