<template>
  <v-card class="user-tab-notification">
    <v-card-title>
      {{userRole}} - Permissions
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text--primary font-weight-medium">
      Please find below all the user's permissions according to their role.
    </v-card-text>

    <v-data-table
      :headers="tableColumn"
      :items="permissions"
      hide-default-footer
    >
      <!-- read -->
      <template #[`item.read`]="{item}">
        <v-row
          align="center"
          justify="center"
        >
          <v-checkbox
            v-model="item.actions.read"
            :disabled="item.disabled"
            hide-details
            dense
            class="shrink"
          ></v-checkbox>
        </v-row>
      </template>

      <!-- update -->
      <template #[`item.update`]="{item}">
        <v-row
          align="center"
          justify="center"
        >
          <v-checkbox
            v-model="item.actions.update"
            :disabled="item.disabled"
            hide-details
            dense
            class="shrink"
          ></v-checkbox>
        </v-row>
      </template>

      <!-- delete -->
      <template #[`item.delete`]="{item}">
        <v-row
          align="center"
          justify="center"
        >
          <v-checkbox
            v-model="item.actions.delete"
            :disabled="item.disabled"
            hide-details
            dense
            class="shrink"
          ></v-checkbox>
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import { ref } from '@vue/composition-api'

export default {
  props: {
    userRolePermissions: {
      type: Array,
      required:true
    },
    userRole: {
      type:String,
      required:true
    }
  },
  setup(props) {
    
    const tableColumn = [
      {
        text: 'TYPE',
        value: 'label',
        sortable: false,
      },
      {
        text: 'READ',
        value: 'read',
        sortable: false,
        align: 'center',
      },
      {
        text: 'UPDATE',
        value: 'update',
        sortable: false,
        align: 'center',
      },
      {
        text: 'DELETE',
        value: 'delete',
        sortable: false,
        align: 'center',
      },
    ]

    const rolePermissionsLocal = ref(JSON.parse(JSON.stringify(props.userRolePermissions)))

    const permissions = ref([
      {
        label: 'Dashboard',
        subject: 'dashboard',
        actions:{
          read: false,
          update: false,
          delete: false
        },
        disabled: true,
      },
      {
        label: 'Hubspot',
        resource: 'sales',
        actions:{
        read: false,
        update: false,
        delete: false,
        },
        disabled: true,
      },
      {
        label: 'Projects\' information',
        resource: 'project',
        actions:{
        read: false,
        update: false,
        delete: false,
        },
        disabled: true,
      },
      {
        label: 'Timeline',
        resource: 'timeline',
        actions:{
        read: false,
        update: false,
        delete: false,
        },
        disabled: true,
      },
      {
        label: 'Survey Builder',
        resource: 'survey',
        actions:{
        read: false,
        update: false,
        delete: false,
        },
        disabled: true,
      },
      {
        label: 'Accounting',
        resource: 'accounting',
        actions:{
        read: false,
        update: false,
        delete: false,
        },
        disabled: true,
      },
      {
        label: 'Team Members',
        resource: 'user',
        actions:{
        read: false,
        update: false,
        delete: false,
        },
        disabled: true,
      },
      {
        label: 'Public',
        resource: 'public',
        actions:{
        read: false,
        update: false,
        delete: false,
        },
        disabled: true,
      },
    ])

    permissions.value.map(item => {
      if (rolePermissionsLocal.value && rolePermissionsLocal.value[0].action === 'manage' && rolePermissionsLocal.value[0].subject === 'all') {
        item.actions.read = true
        item.actions.update = true
        item.actions.delete = true
      } else {
        let resources = rolePermissionsLocal.value.filter(role => role.subject === item.resource)
        resources.map(resource => {
          item.actions[resource.action] = true
        })
      }

    })

    return {
      tableColumn,
      permissions,
    }
  },
}
</script>
