var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"project-list"}},[_c('app-card-actions',{staticClass:"mb-7",attrs:{"no-actions":"","refresh":_vm.refreshProjects}},[_c('template',{slot:"title"},[_c('span',{staticClass:"align-start"},[_vm._v("Project List")])]),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.userProjects,"loading":_vm.loading,"options":_vm.options,"footer-props":{
          'items-per-page-options':[_vm.options.itemsPerPage],
          'disable-items-per-page': true,
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.job",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-left"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'project-view', params : { id: item.job_number } }}},[_vm._v(" "+_vm._s(item.job)+" ")]),_c('small',[_vm._v("@"+_vm._s(item.office))])],1)])]}},{key:"item.job_status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveProjectStatusVariant(item.job_status)) + "--text"),attrs:{"small":"","color":_vm.resolveProjectStatusVariant(item.job_status)}},[_vm._v(" "+_vm._s(item.job_status)+" ")])]}},{key:"item.service_type",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.service_type))]),_c('span',[_vm._v(_vm._s(item.project_type))])])]}},{key:"item.report_date",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-xs text-truncate"},[_c('strong',[_vm._v("Field Start:")]),_vm._v(" "+_vm._s(item.field_start))]),_c('span',{staticClass:"text-xs"},[_c('strong',[_vm._v("Report:")]),_vm._v(" "+_vm._s(item.report_date))])])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{name:'project-view',params:{id:item.job_number} }}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChartTimeline)+" ")]),_c('span',[_vm._v("Timeline")])],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMicrosoftSharepoint)+" ")]),_c('span',[_vm._v("Sharepoint")])],1)],1),(_vm.$can('update', 'survey'))?_c('v-list-item',{attrs:{"to":{name:'survey-view',params:{id:item.job_number}}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiOrderBoolDescendingVariant)+" ")]),_c('span',[_vm._v("Survey Builder")])],1)],1):_vm._e()],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('card-service-type-summary',{attrs:{"userData":_vm.userData}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('card-project-summary',{attrs:{"userData":_vm.userData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }