import axios from '@axios'
import store from '@/store'
import { transformRequestOptions } from '@core/utils'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCourses(ctx, queryParams) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
              axios
                .get('/academy/courses/', {
                    params: queryParams,
                    paramsSerializer: params => transformRequestOptions(params) 
              })
                .then(response => {
                  store.commit('app/LOADING', false)
                  resolve(response)
                })
                .catch(error => { 
                  store.commit('app/LOADING', false)
                  reject(error) 
                })
            })
        },
        fetchCourse(ctx, slug) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
              axios
                .get(`/academy/courses/${slug}/`)
                .then(response => {
                  store.commit('app/LOADING', false)
                  resolve(response)
                })
                .catch(error => { 
                  store.commit('app/LOADING', false)
                  reject(error) 
                })
            })
        },
        addCourse(ctx, payload) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
              axios
                .post('/academy/courses/', payload)
                .then(response => {
                  store.commit('app/LOADING', false)
                  resolve(response)
                })
                .catch(error => { 
                  store.commit('app/LOADING', false)
                  reject(error) 
                })
            })
        },
        updateCourse(ctx, payload) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
              axios
                .patch(`/academy/courses/${payload.slug}/`, payload)
                .then(response => {
                  store.commit('app/LOADING', false)
                  resolve(response)
                })
                .catch(error => { 
                  store.commit('app/LOADING', false)
                  reject(error) 
                })
            })
        },
        updateCategoryCourse(ctx, payload) {
            store.commit('app/LOADING', true)
            return new Promise((resolve, reject) => {
              axios
                .patch(`/academy/courses/${payload.id}/`, payload)
                .then(response => {
                  store.commit('app/LOADING', false)
                  resolve(response)
                })
                .catch(error => { 
                  store.commit('app/LOADING', false)
                  reject(error) 
                })
            })
        },
        fetchLanguages(ctx, queryParams) {
          return new Promise((resolve, reject) => {
            axios
              .get('/academy/languages/', {
                  params: queryParams,
                  paramsSerializer: params => transformRequestOptions(params) 
            })
              .then(response => {
                resolve(response)
              })
              .catch(error => { 
                reject(error) 
              })
          })
      },
        fetchCategories(ctx, queryParams) {
          return new Promise((resolve, reject) => {
            axios
              .get('/academy/categories/', {
                  params: queryParams,
                  paramsSerializer: params => transformRequestOptions(params) 
            })
              .then(response => {
                resolve(response)
              })
              .catch(error => { 
                reject(error) 
              })
          })
      },
      addCategory(ctx, data) {
        store.commit('app/LOADING', true)
        return new Promise((resolve, reject) => {
          axios
            .post('/academy/categories/', data)
            .then(response => {
              store.commit('app/LOADING', false)
              resolve(response)
            })
            .catch(error => { 
              store.commit('app/LOADING', false)
              reject(error) 
            })
        })
      },
      updateCategory(ctx, payload) {
        store.commit('app/LOADING', true)
        return new Promise((resolve, reject) => {
          axios
            .patch(`/academy/categories/${payload.id}/`, payload)
            .then(response => {
              store.commit('app/LOADING', false)
              resolve(response)
            })
            .catch(error => { 
              store.commit('app/LOADING', false)
              reject(error) 
            })
        })
      },
      fetchInstructors(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/academy/instructors/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params) 
          })
            .then(response => {
              resolve(response)
            })
            .catch(error => { 
              reject(error) 
            })
        })
    },
    addInstructor(ctx, data) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post('/academy/intructors/', data)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    updateInstructor(ctx, payload) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/academy/intructors/${payload.id}/`, payload)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/min/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStudent(ctx, email) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/academy/students/${email}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStudentCompletedCourses(ctx, email) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/academy/students/${email}/courses/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStudentsToCompletedCourse(ctx, payload){
      store.commit('app/LOADING', true)
        return new Promise((resolve, reject) => {
          axios
            .post('/academy/completed_courses/', payload)
            .then(response => {
              store.commit('app/LOADING', false)
              resolve(response)
            })
            .catch(error => { 
              store.commit('app/LOADING', false)
              reject(error) 
            })
        })
    }
  }
}
