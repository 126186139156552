<template>
  <div id="project-list">
    
    <!-- list filters -->
    <app-card-actions
     class="mb-7"
      no-actions
      :refresh="refreshProjects"
    >
      <template slot="title">
        <span class="align-start">Project List</span>
      </template>
      

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userProjects"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
            'items-per-page-options':[options.itemsPerPage],
            'disable-items-per-page': true,
        }"
      >
        <!-- job name -->
        <template #[`item.job`]="{item}">
          <div class="d-flex align-left">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'project-view', params : { id: item.job_number } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.job }}
              </router-link>
              <small>@{{ item.office }}</small>
            </div>
          </div>
        </template>

        <!-- status -->
        <template #[`item.job_status`]="{item}">
          <v-chip
            small
            :color="resolveProjectStatusVariant(item.job_status)"
            :class="`${resolveProjectStatusVariant(item.job_status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.job_status }}
          </v-chip>
        </template>

        <!-- Service Type -->
        <template #[`item.service_type`]="{item}">
          <div class="d-flex flex-column ms-3">
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.service_type }}</span>
            <span>{{ item.project_type }}</span>
          </div>
        </template>


        <!-- Dates -->
        <template #[`item.report_date`]="{item}">
          <div class="d-flex flex-column">
            <span class="text-xs text-truncate"><strong>Field Start:</strong> {{ item.field_start }}</span>
            <span class="text-xs"><strong>Report:</strong> {{ item.report_date }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'project-view',params:{id:item.job_number} }"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiChartTimeline }}
                  </v-icon>
                  <span>Timeline</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiMicrosoftSharepoint }}
                  </v-icon>
                  <span>Sharepoint</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{name:'survey-view',params:{id:item.job_number}}"
                v-if="$can('update', 'survey')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiOrderBoolDescendingVariant }}
                  </v-icon>
                  <span>Survey Builder</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </app-card-actions>

    <!-- User Statistics -->
    <v-row >
      <v-col
        cols="12"
        md="6"
      >
        <card-service-type-summary
          :userData="userData"
        ></card-service-type-summary>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <card-project-summary
          :userData="userData"
        ></card-project-summary>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import CardServiceTypeSummary from './CardServiceTypeSummary.vue'
import CardProjectSummary from './CardProjectSummary.vue'
import {
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiChartTimeline,
  mdiMicrosoftSharepoint,
  mdiOrderBoolDescendingVariant,
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { stringToHslColor } from '@core/utils'
import projectStoreModule from '@/views/apps/project/projectStoreModule'

import useProjectsList from '@/views/apps/project/project-list/useProjectsList'
import useUserView from '../useUserView'


export default {
  components: {
    AppCardActions,
    CardServiceTypeSummary,
    CardProjectSummary,
  },
  props: {
    userData: {
      type: Object,
      required:true
    },
  },
  setup(props) {
    
    const PROJECT_APP_STORE_MODULE_NAME = 'app-project'

    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
    })
    
    
    const selectedRows = ref([])
    
    const {
      resolveProjectStatusVariant,
      resolveProjectTotalIcon
    } = useProjectsList()

    const {
      userProjects,
      fetchUserProjects,
      loading,
      options,
      refreshProjects,
    } = useUserView()


    const tableColumns = [
      { text: 'PROJECT', value: 'job'},
      { text: 'STATUS', value: 'job_status'},
      { text: 'SERVICE/PROJ TYPE', value: 'service_type'},
      { text: 'CLIENT', value: 'client'},
      { text: 'DATES', value: 'report_date'},
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    
    watch(
      () => props.userData,
      () => {
        fetchUserProjects()
      }
    )

    return {
      refreshProjects, 
      userProjects,
      tableColumns,
      options,
      loading,
      selectedRows,
      avatarText,
      stringToHslColor,
      resolveProjectStatusVariant,
      resolveProjectTotalIcon,


      // icons
      icons: {
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiChartTimeline,
        mdiMicrosoftSharepoint,
        mdiOrderBoolDescendingVariant
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/project.scss';
</style>
