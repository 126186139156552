import store from '@/store'
import {
  mdiAccountOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

export default function useUsersList() {
  const userListTable = ref([])

  const tableColumns = [
    { text: 'MEMBER', value: 'name' },
    // { text: 'EMAIL', value: 'email' },
    { text: 'ROLE', value: 'role', sortable: false },
    { text: 'TITLE', value: 'title', sortable: false },
    { text: 'COUNTRY', value: 'office_name', sortable: false },
    { text: `${new Date().getFullYear()} PROJECTS`, value: 'current_year_projects', sortable: false, width: "50px" },
    { text: `${new Date().getFullYear()} TASKS`, value: 'current_year_tasks', sortable: false },
    { text: 'TOTAL PROJECTS', value: 'total_projects', sortable: false },
    { text: 'TOTAL TASKS', value: 'total_tasks', sortable: false },
    
    
  ]
  const searchQuery = ref('')
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    itemsPerPage: parseInt(process.env.VUE_APP_ITEMS_PER_PAGE),
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchUsers = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    store
      .dispatch('app-user/fetchUsers', {
        id: user.email
      })
      .then(response => {
        const { data } = response

        userListTable.value = data

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'Director') return 'primary'
    if (role === 'Manager') return 'warning'
    if (role === 'Associate') return 'success'
    if (role === 'Accountant') return 'info'
    if (role === 'Admin') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'Director') return mdiAccountOutline
    if (role === 'Manager') return mdiCogOutline
    if (role === 'Associate') return mdiDatabaseOutline
    if (role === 'Accountant') return mdiPencilOutline
    if (role === 'Admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    planFilter,
    statusFilter,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
  }
}
