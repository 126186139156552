import store from '@/store'
import router from '@/router'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { formatTasks, formatOptions } from '@core/utils'
import { foundryRequest } from '@/config/authConfig';
import { avatarText, diffInDays } from '@core/utils/filter'
import { stringToHslColor } from '@core/utils'
import useProjectsList from '@/views/apps/project/project-list/useProjectsList'
import { TabTranslationPlugin } from 'survey-creator-core';

export default function useUserView() {
  
    const vm = getCurrentInstance().proxy
    
    const userData = ref({})

    const deptOptions = ref([])
    const deptLoading = ref(false)

    const officeOptions = ref([])
    const officeLoading = ref(false)

    const groupLoading = ref(false)
    const groupOptions = ref([])

    const loading = ref(false)

    const userProjects = ref([])
    const refreshProjects = ref(false)
    
    const userProjectStatusSummary = ref([])
    const refreshProjectSummary = ref(false)
    
    const userServTypeSummary = ref([])
    const refreshServTypeSummary = ref(false)
    
    const userTasks = ref([])
    const refreshTasks = ref(false)

    const searchQuery = ref('')
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: parseInt(process.env.VUE_APP_ITEMS_PER_PAGE),
    })

    
    
    const {
      resolveProjectStatusVariant
    } = useProjectsList()


    

    const fetchUser = async () => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      const email = router.currentRoute.params.email || JSON.parse(localStorage.getItem('user')).email
      store
        .dispatch('app-user/fetchUser', { 
          id: email,
          search: searchQuery.value,
          options: JSON.stringify(options.value),
          page: options.value.page
        })
        .then(response => {
          userData.value = response.data
          
          userData.value.color = stringToHslColor(userData.value.name)
          userData.value.initials = avatarText(userData.value.name)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            userData.value = {}
          }
          if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
        })
    }

    const fetchUserProjects = async () => {
      refreshProjects.value = true
      const user = JSON.parse(localStorage.getItem('user')) 
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-user/fetchUserProjects', { 
          id: user.email,
        })
        .then(response => {
          userProjects.value = response.data
          userProjects.value.map(project => {
              
            project['job'] = `${project.job_number} - ${project.job_name}`
              
          })
          refreshProjects.value = false
          
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }
    
    const fetchUserProjectStatusSummary = async () => {
      refreshProjectSummary.value = true
      const user = JSON.parse(localStorage.getItem('user')) 
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-user/fetchUserProjectStatusSummary', { 
          id: user.email,
        })
        .then(response => {
          const { data } = response
          const statuses = []
          for (const status of data) {
            statuses.push(
              {
                title: status.name,
                count: status.count,
                color: resolveProjectStatusVariant(status.name)
              }
            )
          }
          userProjectStatusSummary.value = statuses
          refreshProjectSummary.value = false
          
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }
    const fetchUserServTypeSummary = async () => {
      refreshServTypeSummary.value = true
      const user = JSON.parse(localStorage.getItem('user')) 
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-user/fetchUserServTypeSummary', { 
          id: user.email,
        })
        .then(response => {
          const { data } = response
          const servTypes = []
          for (const serviceType of data) {
            servTypes.push(
              {
                title: serviceType.name,
                count: serviceType.count,
                color: stringToHslColor(serviceType.name)
              }
            )
          }
          userServTypeSummary.value = servTypes
          refreshServTypeSummary.value = false
          
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }

    const fetchUserTasks = async () => {
      refreshTasks.value = true
      const user = JSON.parse(localStorage.getItem('user') || {})
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-user/fetchUserTasks', { 
          id: user.email
        })
        .then(response => {
          const { personals, project_tasks } = response.data
          const tasks = [
            ...personals,
            ...project_tasks
          ]

          tasks.map(task => {
            normalizeTask(task)
            
          })
          
          userTasks.value = tasks

          refreshTasks.value = false
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }


    const updateUser = async (data) => {
      loading.value = true
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
        .dispatch('app-user/updateUser', {
          id:data.azure_ad_id,
          data: data
        })
        .then(response => {
          const { data } = response
          userData.value = data
          userData.value.color = stringToHslColor(userData.value.azure_ad_id),
          userData.value.initials = avatarText(userData.value.name)
          
          updateLocalStorageUser(JSON.parse(JSON.stringify(userData.value)))
          
          const {user_ability:userAbility} = userData.value
          vm.$ability.update(userAbility)
          delete userData.value.user_ability
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // remove loading state
          loading.value = false
          
        })
        .catch(error => {
          console.log(error)
        })
    }


    const fetchGroups = async () => {
      groupLoading.value = true
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      
      store
        .dispatch('app-user/fetchGroups', {
        })
        .then(response => {
          const { data } = response
          groupOptions.value = formatOptions(data)
          // remove loading state
          groupLoading.value = false
          
        })
        .catch(error => {
          console.log(error)
        })
    }
    const fetchDepts = async () => {
        deptLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
          .dispatch('app-user/fetchDepts', {
          })
          .then(response => {
            const { data } = response
            deptOptions.value = formatOptions(data)
            // remove loading state
            deptLoading.value = false
            
          })
          .catch(error => {
            console.log(error)
          })
    }

    const fetchOffices = async () => {
        officeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
          .dispatch('app-user/fetchOffices', {
          })
          .then(response => {
            const { data } = response
            officeOptions.value = formatOptions(data)
            // remove loading state
            officeLoading.value = false
            
          })
          .catch(error => {
            console.log(error)
          })
    }

    const normalizeTask = (task) => {
      if ('project' in task) {
        task.type = 'Project'
      } else {
        task.type = 'Personal'
      }
      
      if (task.percent_complete === 100){
        task.status = 'complete'
      } else if (task.percent_complete > 0 && task.percent_complete < 100) {
        task.status = 'ongoing'
      } else if (diffInDays( 'today', task.due_date_time) < 0) {
        task.status = 'overdue'
      } else { 
        task.status = 'notstarted'
      } 
      
      task.fill = resolveTaskStatusVariant(task.status)
      task.statusText = resolveTaskStatusLabelVariant(task.status)

      return task
    }

    const resolveTaskStatusVariant = status => {
      if (status === 'notstarted') return 'primary'
      if (status === 'complete') return 'success'
      if (status === 'ongoing') return 'accent'
      if (status === 'overdue') return 'error'
  
      return 'primary'
    }

    const resolveTaskStatusLabelVariant = status => {
      if (status === 'notstarted') return 'Not Started'
      if (status === 'complete') return 'Complete'
      if (status === 'ongoing') return 'On Going'
      if (status === 'overdue') return 'Overdue'
  
      return 'primary'
    }

    const updateLocalStorageUser = user => {
      delete user.total_projects
      delete user.total_tasks
      
      localStorage.setItem('user', JSON.stringify(user))

    }

    return {
        userData,
        userProjects,
        userProjectStatusSummary,
        userServTypeSummary,
        userTasks,
        groupLoading,
        groupOptions,
        deptOptions,
        deptLoading,
        officeOptions,
        officeLoading,
        loading,
        searchQuery,
        options,
        refreshProjects,
        refreshProjectSummary,
        refreshServTypeSummary,
        refreshTasks,
        normalizeTask,

        fetchDepts,
        fetchOffices,
        fetchGroups,
        fetchUser,
        updateUser,
        fetchUserProjects,
        fetchUserProjectStatusSummary,
        fetchUserServTypeSummary,
        fetchUserTasks
    }

}