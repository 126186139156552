<template>
  <app-card-actions
     class="mb-7"
      no-actions
      :refresh="refreshProjectSummary"
    >
    <template slot="title">
      <span class="align-start">Project Status Summary</span>
      
    </template>

    <v-card-text>
      <div
        v-for="(data,index) in userProjectStatusSummary"
        :key="data.title"
        :class="`statistics-table d-flex align-center pt-3 ${index > 0 ? 'mt-3':''}`"
      >
        <v-chip
            small
            :color="data.color"
            :class="`${data.color}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ data.title }}
          </v-chip>
        <v-spacer></v-spacer>
        <span class="font-weight-semibold text--primary me-6">{{ data.count }}</span>
      </div>
    </v-card-text>

  </app-card-actions>
</template>

<script>

import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { ref, watch } from '@vue/composition-api'
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import useUserView from '../useUserView'

export default {
  components: {
    AppCardActions
  },
   props: {
    userData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {
      refreshProjectSummary,
      fetchUserProjectStatusSummary,
      userProjectStatusSummary
    } = useUserView()

    watch(
      () => props.userData,
      () => {
        fetchUserProjectStatusSummary()
      }
    )

    return {
      userProjectStatusSummary,
      refreshProjectSummary,
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
