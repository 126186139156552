<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit User Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating user details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="userDataLocal.name"
                outlined
                dense
                disabled
                label="Full Name"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
            >
              <v-select
                v-model="userDataLocal.department"
                :loading="deptLoading"
                :items="deptOptions"
                item-text="label"
                item-value="value"
                outlined
                dense
                label="Department"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-select
                v-model="userDataLocal.favorite_teams_group"
                :loading="groupLoading"
                :items="groupOptions"
                item-text="label"
                item-value="value"
                outlined
                dense
                label="Teams Group"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-select
                v-model="userDataLocal.office"
                :loading="officeLoading"
                :items="officeOptions"
                item-text="label"
                item-value="value"
                outlined
                dense
                label="Office"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="userDataLocal.notification"
                class="mt-0"
                hide-details
              >
                <template #label>
                  <span class="font-weight-medium text--primary">Receive notification?</span>
                </template>
              </v-switch>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :loading="loading"
                @click.prevent="onSubmit"
              >
                Update
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open',false)"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import useUserView from '../../user-view/useUserView'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true
    },
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)

    const userDataLocal = ref({})

    const {
      groupLoading,
      groupOptions,
      deptOptions,
      deptLoading,
      officeOptions,
      officeLoading,

      fetchDepts,
      fetchOffices,
      fetchGroups
    } = useUserView()

    fetchGroups()
    const initUserDataLocal = () => {
      userDataLocal.value = {
        id: props.userData.id,
        name: props.userData.name,
        azure_ad_id: props.userData.azure_ad_id,
        office: props.userData.office,
        department: props.userData.department,
        favorite_teams_group: props.userData.favorite_teams_group,
        notification: props.userData.notification,
      }
    }

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    // on form submit
    const onSubmit = () => {
      emit('update', userDataLocal)
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        initUserDataLocal()
        fetchGroups()
        fetchDepts()
        fetchOffices()
      },
    )

    watch(
      () => props.userData,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
      emit('update:is-bio-dialog-open', false)
      },
    )

    return {
      groupLoading,
      groupOptions,
      deptOptions,
      deptLoading,
      officeOptions,
      officeLoading,
      isBillingAddress,
      statusOptions,
      languageOptions,
      userDataLocal,
      onSubmit,
    }
  },
}
</script>
