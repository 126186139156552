import axios from '@axios'
import store from '@/store'
import { transformRequestOptions } from '@core/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchUsers(ctx, {id}) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}/direct_report/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)})
      })
    },
    // fetchUsers(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/user/users', { params: queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchUserProjects(ctx, { id }){
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}/projects/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)})
      })
    },
    fetchUserProjectStatusSummary(ctx, { id }){
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}/status_summary/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)})
      })
    },
    fetchUserServTypeSummary(ctx, { id }){
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}/service_type_summary/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)})
      })
    },
    fetchUser(ctx, { id }) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)})
      })
    },
    fetchUserTasks(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/planners/users/${id}/?fields=project_tasks,personals,email`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)})
      })
    },
    updateUser(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/users/profiles/${id}/`, {
            ...data
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)})
      })
    },
    
    fetchGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/teamsgroups/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/departments/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOffices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/offices/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
