<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="userData.color || 'primary'"
            class="mb-4"
            size="120"
            rounded
          >
            <v-img
              v-if="userData.avatar"
              :src="require(`@/assets/images/avatars/${userData.avatar}`)"
            ></v-img>
            <span
              v-else
              class=" white--text text-5xl"
            >{{ userData.initials }}</span>
          </v-avatar>

          <span class="mb-2">{{ userData.name }}</span>

          <v-chip
            label
            small
            :color="resolveUserRoleVariant()"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant()}--text text-capitalize`"
          >
            {{ userData.job_title }}
          </v-chip>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="success"
              class="v-avatar-light-bg success--text me-3"
            >
              <v-icon
                color="success"
                size="22"
              >
                {{ icons.mdiClipboardCheckOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.total_tasks) }}
              </h3>
              <span>Tasks</span>
            </div>
          </div>

          <div class="d-flex align-center mb-4 me-4">
            <v-avatar
              size="40"
              rounded
              color="info"
              class="v-avatar-light-bg info--text me-3"
            >
              <v-icon
                color="info"
                size="22"
              >
                {{ icons.mdiFileDocumentMultipleOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.total_projects) }}
              </h3>
              <span>Projects</span>
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Name:</span>
              <span class="text--secondary">{{ userData.name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>

            <!-- <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Status:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(userData.status)"
                  :class="`v-chip-light-bg ${resolveUserStatusVariant(userData.status)}--text font-weight-medium text-capitalize`"
                >
                  {{ userData.status }}
                </v-chip>
              </span>
            </v-list-item> -->

            <v-list-item
              dense
              class="px-0 mb-n2"
              v-if="userData.role"
            >
              <span class="font-weight-medium me-2">Role:</span>
              <span class="text--secondary text-capitalize">{{ userData.role.name }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Dept:</span>
              <span class="text--secondary">{{ userData.department_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Teams Group:</span>
              <span class="text--secondary">{{ userData.favorite_teams_group_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Office:</span>
              <span class="text--secondary">{{ userData.office_name }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Notification:</span>
              <v-switch
                v-model="userData.notification"
                color="primary"
                disabled
                value
              ></v-switch>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- <v-card class="mt-6">
        <v-card-title>Badges</v-card-title>
        <v-card-text>
            <div class="badge">
              <div class="prsivBadge bronze">
                <div class="notify">99</div>
                <div class="circle">
                  <v-icon class="c-icon" large>
                  </v-icon>
                </div>
                <div class="ribbon">Core</div>
              </div>
              <div class="prsivBadge silver" >
                <div class="notify">1</div>
                <div class="circle">
                  <v-icon class="c-icon" large>
                  </v-icon>
                </div>
                <div class="ribbon">Intermediate</div>
              </div>
              <div class="prsivBadge gold" >
                <div class="notify">0</div>
                <div class="circle">
                  <v-icon class="c-icon" large>
                  </v-icon>
                </div>
                <div class="ribbon">Advanced</div>
              </div>
            </div>
            
            <div class="badge">
              
            <div class="badge-ribbon teal">
                <div class="circle">
                  <v-icon class="c-icon" large>
                    {{ icons.mdiCartOutline }}
                  </v-icon>
                </div>
                <div class="ribbon">Shopper</div>
            </div>
          </div>
          

        </v-card-text>

      </v-card> -->


      <!-- edit profile dialog data -->
      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :loading="loading"
        :user-data="userData"
        @update="updateUserDetails"
      ></user-bio-edit>
    </v-col>

  </v-row>
</template>

<script>
import { mdiClipboardCheckOutline, mdiFileDocumentMultipleOutline, mdiCartOutline } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref, emit } from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'
import useUsersList from '../../user-list/useUsersList'

export default {
  components: {
    UserBioEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  setup(props, {emit}) {
    const { resolveUserStatusVariant, resolveUserRoleVariant } = useUsersList()

    const isBioDialogOpen = ref(false)
    
    const updateUserDetails = (userDataLocal) => {
      emit('update', userDataLocal)
    }

    return {
      resolveUserStatusVariant,
      resolveUserRoleVariant,
      avatarText,
      kFormatter,
      isBioDialogOpen,
      icons: {
        mdiClipboardCheckOutline,
        mdiFileDocumentMultipleOutline,
        mdiCartOutline
      },

      updateUserDetails
    }
  },
}
</script>

<style scoped>
 
  
</style>
