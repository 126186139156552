import store from '@/store'

import { ref, getCurrentInstance } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { formatOptions } from '@core/utils'


export default function useCourse() {
    // Stepper's active step
    const vm = getCurrentInstance().proxy

    const instructorLoading = ref(false)
    const instructorOptions = ref([])
    const instructorSearch = ref('')

    const categoriesLoading = ref(false)
    const categoriesOptions = ref([])
    const categoriesSearch = ref('')

    const languagesLoading = ref(false)
    const languagesOptions = ref([])
    const languagesSearch = ref('')

    const coursesLoading = ref(false)
    const coursesOptions = ref([])
    const coursesSearch = ref('')
    const totalCourses = ref(0)
    
    const courseData = ref({})

    const coursesPage = ref(1)
    const infiniteId =ref(+new Date())

    const userLoading = ref(false)
    const userOptions = ref([])

    const studentLoading = ref(false)
    const studentData = ref({})

    const studentCoursesLoading= ref(false)
    const studentCompletedCourses = ref([])

    const studentsToCompletedLoading = ref(false)
    
    const resolveCourseLevelVariant = level => {
        if (level === 'CORE') return 'primary'
        if (level === 'INTERMEDIATE') return 'info'
        if (level === 'ADVANCED') return 'accent'
        if (level === 'QUAL') return 'success'
        if (level === 'SHOPPER') return 'warning'
        if (level === 'INNOVATION') return 'error'

        return 'primary'
    }


    const fetchCategories = async () => {
        categoriesLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-academy/fetchCategories', )
        .then(response => {
            const { data } = response
            categoriesOptions.value = data
            // remove loading state
            categoriesLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
        
    const fetchLanguages = async () => {
        languagesLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-academy/fetchLanguages', )
        .then(response => {
            const { data } = response
            languagesOptions.value = data
            // remove loading state
            languagesLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    const fetchCourses = async (state= {
        loaded: () => {},
        complete: () => {}
      }) => {
        coursesLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-academy/fetchCourses', {
            page:coursesPage.value,
            search:coursesSearch.value
            
        })
        .then(response => {
            const { results, count, total_pages } = response.data
            totalCourses.value = count
            coursesOptions.value.push(...results)
            // remove loading state
            coursesLoading.value = false

            if (coursesPage.value < total_pages){
            
                state.loaded()
                coursesPage.value += 1
              } else {
                state.complete()
              }
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchCourse = async (slug) => {
        coursesLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        await store
        .dispatch('app-academy/fetchCourse', slug)
        .then(response => {
            const { data } = response
            courseData.value = data
            
            // remove loading state
            coursesLoading.value = false

        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchUsers = async () => {
        userLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-academy/fetchUsers', {
        })
        .then(response => {
            const { data } = response
            userOptions.value = data
            // remove loading state
            userLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    
    const fetchStudent = async (email) => {
        studentLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-academy/fetchStudent', email)
        .then(response => {
            const { data } = response
            studentData.value = data
            // remove loading state
            studentLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchStudentCompletedCourses = async (email) => {
        studentCoursesLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-academy/fetchStudentCompletedCourses', email)
        .then(response => {
            const { data } = response
            studentCompletedCourses.value = data.results
            // remove loading state
            studentCoursesLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const addStudentsToCompletedCourse = async (payload) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        studentsToCompletedLoading.value = true
        store
            .dispatch('app-academy/addStudentsToCompletedCourse', payload)
            .then((response) => {
                const { data } = response
                studentsToCompletedLoading.value = false
                
                store.commit('comp-alert/SET_MESSAGE', {})
            })
            .catch(error => {
                store.commit('comp-alert/SET_MESSAGE', error.response.data)
            })
    }

    
    const fetchInstructors = async () => {
        instructorLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-academy/fetchInstructors')
        .then(response => {
            const { data } = response
            instructorOptions.value = data
            // remove loading state
            instructorLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

        


    return {
        resolveCourseLevelVariant,
        instructorLoading,
        instructorOptions,
        instructorSearch,
        categoriesLoading,
        categoriesOptions,
        categoriesSearch,
        languagesLoading,
        languagesOptions,
        languagesSearch,
        coursesLoading,
        coursesOptions,
        coursesSearch,
        totalCourses,
        courseData,
        infiniteId,
        coursesPage,

        userLoading,
        userOptions,
        studentLoading,
        studentData,
        studentCoursesLoading,
        studentCompletedCourses,
        studentsToCompletedLoading,

        fetchInstructors,
        fetchCategories,
        fetchLanguages,
        fetchCourses,
        fetchCourse,
        fetchUsers,
        fetchStudent,
        fetchStudentCompletedCourses,
        addStudentsToCompletedCourse
    }

}
