<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="3"
      >
        <user-bio-panel
          :userData="userData"
          :loading="loading"
          @update="updateUserDetails"
        ></user-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="9"
        lg="9"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiAccountOutline }}
            </v-icon>
            <span>Overview</span>
          </v-tab>
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiClipboardCheckOutline }}
            </v-icon>
            <span>Tasks</span>
          </v-tab>
          <v-tab>
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiSchoolOutline }}
            </v-icon>
            <span>Academy</span>
          </v-tab>
          
          <v-tab  v-if="$can('add','user')">
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiLockOutline }}
            </v-icon>
            <span>Permissions</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            eager
          >
            <user-tab-overview
            :userData="userData"
            ></user-tab-overview>
          </v-tab-item>
          <v-tab-item
            eager
          >
            <user-tab-tasks
              :userData="userData"
            ></user-tab-tasks>
          </v-tab-item>
          <v-tab-item
          >
            <academy-dashboard></academy-dashboard>
          </v-tab-item>
          <!-- <v-tab-item
          >
            <user-tab-calendar
            ></user-tab-calendar>
          </v-tab-item> -->
          <v-tab-item v-if="$can('add','user')">
            <user-tab-permissions
              v-if="userData.role"
              :user-role-permissions="userData.user_ability"
              :user-role="userData.role.name"
            >
            </user-tab-permissions>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { onUnmounted, ref, onMounted } from '@vue/composition-api'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline, 
  mdiClipboardCheckOutline, 
  mdiLockOutline, 
  mdiCalendarCheckOutline, 
  mdiSchoolOutline
} from '@mdi/js'
import userStoreModule from '../userStoreModule'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import UserTabTasks from './user-tab-tasks/UserTabTasks.vue'
import UserTabPermissions from './user-tab-permissions/UserTabPermissions.vue'
import UserBioPanel from '../user-profile/user-bio-panel/UserBioPanel.vue'

import useUserView from './useUserView'
import timelineStoreModule from '@/views/apps/timeline/timelineStoreModule'
import projectStoreModule from '@/views/apps/project/projectStoreModule'
import VerticalNavMenu from '@/@core/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import AcademyDashboard from '@/views/apps/academy/dashboard/AcademyDashboard.vue'

export default {
  components: {
    UserBioPanel,
    UserTabOverview,
    UserTabTasks,
    UserTabPermissions,
    VerticalNavMenu,
    AcademyDashboard
  },
  setup() {
    const USER_CALENDAR_APP_STORE_MODULE_NAME = 'app-timeline'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const PROJECT_APP_STORE_MODULE_NAME = 'app-project'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(USER_CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(USER_CALENDAR_APP_STORE_MODULE_NAME, timelineStoreModule)
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_CALENDAR_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
    })

    const userTab = ref(null)

    const {
      loading,
      userData,
      fetchUser,
      updateUser
    } = useUserView()
    

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiClipboardCheckOutline, title: 'Tasks' },
      { icon: mdiSchoolOutline, title: 'Academy' },
      { icon: mdiLockOutline, title: 'Permissions' }
    ]

    const updateUserDetails = async (userDataLocal) => {
      const data = JSON.parse(JSON.stringify(userDataLocal.value))
      updateUser(data)
    }


    onMounted(() => {
      fetchUser()
    })

    return {
      tabs,
      userTab,
      userData,
      loading,
      updateUserDetails,

      icons: {
        mdiAccountOutline,
        mdiClipboardCheckOutline,
        mdiLockOutline,
        mdiCalendarCheckOutline,
        mdiSchoolOutline
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
