import store from '@/store'
import router from '@/router'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { stringToHslColor, dateInPast } from '@core/utils'
import { diffInDays } from '@core/utils/filter'

export default function useProjectView() {

  const vm = getCurrentInstance().proxy
  const projectData = ref({})
  const updateLoading = ref(false)
  const csatSurvey = ref({})
  const csatResult = ref([])

  // fetch data
  const fetchProject = async () => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    
    store
      .dispatch('app-project/fetchProject', {
        id:router.currentRoute.params.id
      })
      .then(response => {
        const { data } = response

        projectData.value = updateData(data)

      })
      .catch(error => {
        console.log(error)
        router.push({
          name: 'misc-error'
        })
      })
  }

  const updateProject = async (data) => {
    updateLoading.value = true
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    store
      .dispatch('app-project/updateProject', {
        id:router.currentRoute.params.id || data.job_number,
        data: data
      })
      .then(response => {
        const { data } = response
        
        projectData.value = updateData(data)
        // params.loading.value = false

        store.commit('comp-alert/SET_MESSAGE', {})
        
      })
      .catch(error => {
        console.log(error)
        updateLoading.value = false
        store.commit('comp-alert/SET_MESSAGE', error.response.data)
        // router.push({
        //   name: 'misc-error'
        // })
      })
      updateLoading.value = false
  }

  const fetchCSATSurvey = async () => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    store
      .dispatch('app-project/fetchCSATSurvey', {
        id:`csat.${router.currentRoute.params.id.toLowerCase()}`
      })
      .then(response => {
        const { data } = response
        
        csatSurvey.value = data
        
      })
      .catch(error => {
        console.log(error)
        // updateLoading.value = false
        // router.push({
        //   name: 'misc-error'
        // })
      })
  }

  const fetchCSATResult = async () => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    store
      .dispatch('app-project/fetchCSATResult', {
        id:`csat.${router.currentRoute.params.id.toLowerCase()}`
      })
      .then(response => {
        const { data } = response
        csatResult.value = data
      })
      .catch(error => {
        console.log(error)
        // updateLoading.value = false
        // router.push({
        //   name: 'misc-error'
        // })
      })
  }
  
  const addCSATResult = async (csatResult) => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    const csatResultLocal = JSON.parse(JSON.stringify(csatResult))
    store
      .dispatch('app-project/addCSATResult', csatResultLocal)
      .then(response => {
        const { data } = response
        csatResult.value = [data]
        fetchCSATResult()
      })
      .catch(error => {
        console.log(error)
      })
  }

  
  const addMarket = async (data) => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    store
      .dispatch('app-project/addMarket', {id: router.currentRoute.params.id.toLowerCase(), data})
      .then(response => {
        const { data } = response
        fetchProject()
        
      })
      .catch(error => {
        console.log(error)
      })
  }


  const updateData = (data) => {

    const daysInField = diffInDays(data.field_start, data.field_end, true)
    const fieldStartInDate = dateInPast(new Date(data.field_start), new Date())
    const daysStarted = fieldStartInDate ? Math.min(diffInDays('today', data.field_start, true), daysInField) : 0
    return {
      ...data,
      color: stringToHslColor(data.job_name),
      days_in_field: daysInField,
      days_started: daysStarted,
      days_remaining: daysInField-daysStarted <= 0 ? 0: daysInField-daysStarted,
      field_progress: daysStarted / daysInField * 100
    }

  }

  const resolveCountryStatusVariant = status => {
    if (status === 'PRE') return 'primary'
    if (status === 'IN') return 'info'
    if (status === 'POST') return 'accent'
    if (status === 'COMP') return 'success'
    if (status === 'HOLD') return 'warning'
    if (status === 'CLD') return 'error'

    return 'primary'
  }
  
  const resolveCountryStatusLabelVariant = status => {
    if (status === 'PRE') return 'Pre-Field'
    if (status === 'IN') return 'In-Field'
    if (status === 'POST') return 'Post-Field'
    if (status === 'COMP') return 'Completed'
    if (status === 'HOLD') return 'On-Hold'
    if (status === 'CLD') return 'Cancelled'

    return 'No Status'
  }

  return {
    projectData,
    updateLoading,
    csatSurvey,
    csatResult,
    addMarket,

    fetchProject,
    updateProject,
    fetchCSATSurvey,
    fetchCSATResult,
    addCSATResult,
    resolveCountryStatusVariant,
    resolveCountryStatusLabelVariant
  }
}
