import { render, staticRenderFns } from "./CardServiceTypeSummary.vue?vue&type=template&id=6d418ccd&scoped=true&"
import script from "./CardServiceTypeSummary.vue?vue&type=script&lang=js&"
export * from "./CardServiceTypeSummary.vue?vue&type=script&lang=js&"
import style0 from "./CardServiceTypeSummary.vue?vue&type=style&index=0&id=6d418ccd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d418ccd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VCardText,VSpacer})
