<template>
  <div>
    <v-row>
      <v-col
          cols="12"
          md="8"
          sm="6"
          style="border-right: 1px solid rgba(94, 86, 105, 0.14);"
      >
          <h3>Hi, {{ studentData.name }}</h3>
          <p>Your progress so far is Awesome. let's keep it up and get a lot of badges!</p>
          <v-row
              class="d-flex align-center justify-space-between mx-0">
            <v-col
              cols="12"
              md="4"
              class="d-flex align-center"
            >
              <v-avatar
                size="54"
                rounded
                class="elevation-1 primary"
              >
                <v-icon
                  dark
                  color="white"
                  size="40"
                >
                  {{ icons.mdiLaptop }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-medium-emphasis mb-0">
                  Hours Spent
                </p>
                <h3 class="font-weight-medium primary--text">
                  {{ studentData.course_total_hours }}
                </h3>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="d-flex align-center"
            >
              <v-avatar
                size="54"
                rounded
                class="elevation-1 info"
              >
                <v-icon
                  dark
                  color="white"
                  size="40"
                >
                  {{ icons.mdiCheckDecagramOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-medium-emphasis mb-0">
                  Courses Completed
                </p>
                <h3 class="font-weight-medium info--text">
                  {{ studentData.completed_courses }}
                </h3>
              </div>
            </v-col>
            <!-- <v-col
              cols="12"
              md="4"
              class="d-flex align-center"
            >
              <v-avatar
                size="54"
                rounded
                class="elevation-1 accent"
              >
                <v-icon
                  dark
                  color="white"
                  size="40"
                >
                  {{ icons.mdiCalendarMultiselectOutline }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="mb-0 text-medium-emphasis">
                  Registered
                </p>
                <h3 class="font-weight-medium accent--text">
                  1
                </h3>
              </div>
            </v-col> -->
        
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="6"
      >
      <v-card flat outline color="transparent">
            <v-card-title class="pt-0">Achievements</v-card-title>
            <v-card-text class="pb-0">
              
              <div class="badge d-flex justify-space-between  flex-wrap flex-column flex-md-row">
                <div 
                  v-for="(certificate, i) in studentData.certificates"
                  :key="i"
                  class="prsivBadge "
                  :class="levelColor(certificate.level)">
                  <div class="notify" v-if="certificate.total">{{ certificate.total }}</div>
                  <div class="circle">
                    <v-icon class="c-icon" large :class="`text-${levelColor(certificate.level)}`">
                      {{ icons.mdiMedalOutline }}
                    </v-icon>
                  </div>
                  <div class="ribbon">{{ certificate.level }}</div>
                </div>
                
              </div>

            </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-row>
      <!-- <v-col
          cols="12"
          md="8"
          sm="6"
      >
        <v-card >
          <v-card-title>Expertise Fields</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                v-if="user.expertise"
              >
                
                <vue-apex-charts
                  id="sessions-chart"
                  class="flex-grow-1"
                  :options="chartOptions"
                  :series="expertiseSeries"
                  height="350" 
                ></vue-apex-charts>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="d-flex justify-space-around align-center"
              >
              <v-row>
                
                <v-col 
                  
                  cols="12"
                  sm="6"
                  class="d-flex mb-8 pa-0"
                  v-for="(expertise, i) in user.expertise"
                  :key="i"
                >
                    <v-badge
                      dot
                      inline
                      :color="resolveExpertiseColor(i)"
                      class="mt-1 mx-2"
                    ></v-badge>
                    <div>
                      
                      <div class="text-body-1">
                        {{ expertise.name }}
                      </div>
                      <h5 class="text-h6">{{ expertise.percent }}%</h5>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-card>
          <v-card-text
          >
            <v-img
              height="150"
              src="@/assets/images/3d-characters/not-authorized.png"
              class="img-top"
            ></v-img>
          </v-card-text>
          <v-card-title>
            Upcoming Webinar
          </v-card-title>
          <v-card-subtitle>
            Building the planogram & shelf creation
          </v-card-subtitle>
          <v-card-text
              class="d-flex justify-space-around flex-wrap">
            <div>
              <v-icon
              medium
              color="primary"
              >
              {{icons.mdiCalendarOutline}}
              </v-icon>
              <div
                class="d-inline-flex flex-column justify-center ms-3"
                style="vertical-align:middle"
              >
                <span class="text--primary font-weight-semibold mb-n1 text-md">
                  19 Apr. 24
                </span>
                Date
              </div>
            </div>
            <div>
              <v-icon
              medium
              color="primary"
              >
              {{icons.mdiClockOutline}}
              </v-icon>
              <div
                class="d-inline-flex flex-column justify-center ms-3"
                style="vertical-align:middle"
              >
                <span class="text--primary font-weight-semibold mb-n1 text-md">
                  1h
                </span>
                Duration
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              width="100%"
              color="primary"
            >
              Join The Event
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        sm="4"
      >
        <v-card>
          <v-card-title>
            Available Trainings
          </v-card-title>
          <v-card-text>
            
            <v-data-table
              :headers="trainingHeaders"
              :items="availableTrainings"
              :items-per-page="5"
            ></v-data-table>
          </v-card-text>
        </v-card>
        
      
      </v-col> -->
      <!-- <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-card>
          <v-card-title class="pb-2">
            Top Instructors
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pb-2 d-flex justify-space-between">
            <div>INSTRUCTORS</div>
            <div>COURSES</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="">
            
            <div 
              v-for="(instructor, i) in user.top_instructors"
              :key="i"
              class="pb-3 pt-2 d-flex justify-space-between" 
            >
              <div>
                <v-avatar
                  :color="stringToHslColor(instructor.name) || 'primary'"
                  size="40"
                >
                  
                  <span class="white--text text">{{getInitialName(instructor.name)}}</span>
                </v-avatar>
                <div
                  class="d-inline-flex flex-column justify-center ms-3"
                  style="vertical-align:middle"
                >
                  <span class="text--primary font-weight-semibold mb-n1 text-md">
                    {{ instructor.name }}
                  </span>
                  {{ instructor.role }}
                </div>
              </div>
              <div>
                {{ instructor.count }}
              </div>
            </div>
          </v-card-text>
        </v-card>
        
      
      </v-col> -->
      <v-col
          cols="12"
          md="12"
          sm="12"
      >
        <v-card>
          <v-card-title>Completed trainings</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="courseHeaders"
              :items="studentCompletedCourses"
              :loading="studentCoursesLoading"
            >
            <template #[`item.duration`]="{item}">
              {{ formatDuration(item.duration) }}
            </template>
            <template #[`item.completed_date`]="{item}">
              {{ formatDateToMonthYearShort(item.completed_date) }}
            </template>
          </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>
  </div>
    
</template>

<script>
import { ref, watch, onMounted, onUnmounted, computed } from '@vue/composition-api'
import academyStoreModule from '../academyStoreModule'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'

// eslint-disable-next-line object-curly-newline
import { 
  mdiCheckDecagramOutline,
  mdiMedalOutline,
  mdiLaptop,
  mdiLightbulbOutline,
  mdiCalendarMultiselectOutline,
  mdiCalendarOutline,
  mdiClockOutline
} from '@mdi/js'
import { getVuetify, getInitialName, stringToHslColor, formatDuration } from '@core/utils'
import {formatDateToMonthYearShort } from '@core/utils/filter'

import useCourse from '../useCourse'

export default ({
  components: { VueApexCharts },
  props: {
    statistics: { type: String, default: '' },
    statTitle: { type: String, default: '' },
    chartSeries: {
      type: Array,
      default: () => [{}]
    },
  },
    setup(props) {
      
      const ACADEMY_APP_STORE_MODULE_NAME = 'app-academy'
      // Register module
      if (!store.hasModule(ACADEMY_APP_STORE_MODULE_NAME)) store.registerModule(ACADEMY_APP_STORE_MODULE_NAME, academyStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
      if (store.hasModule(ACADEMY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACADEMY_APP_STORE_MODULE_NAME)
      })
    
      const user = JSON.parse(localStorage.getItem('user') || '{}')

      const {
        studentCoursesLoading,
        studentCompletedCourses,
        studentLoading,
        studentData,

        fetchStudent,
        fetchStudentCompletedCourses
      } = useCourse()



      const $vuetify = getVuetify()
      // const user = ref({
      //   name: "Hideo Maldonado",
      //   total_hours: 34,
      //   courses_completed: 14,
      //   levels:[
      //     {
      //       name: "core",
      //       count: 3,
      //       color: "bronze"
      //     },
      //     {
      //       name: "intermediate",
      //       count: 2,
      //       color: "silver"
      //     },
      //     {
      //       name: "advanced",
      //       count: 1,
      //       color: "gold"
      //     }
      //   ],
      //   expertise: [
      //     {
      //       name: "Onboarding",
      //       percent: 35
      //     },
      //     {
      //       name: "Pack Design",
      //       percent: 20
      //     },
      //     {
      //       name: "Product Testing",
      //       percent: 14
      //     },
      //     {
      //       name: "Validation",
      //       percent: 12
      //     },
      //     {
      //       name: "Concept",
      //       percent: 10
      //     },
      //     {
      //       name: "Consultancy",
      //       percent: 9
      //     },
      //   ],
      //   top_instructors:[
      //     {
      //       name: "Anna Ghanza",
      //       role: "Head of Global Qual",
      //       count: 10
      //     },
      //     {
      //       name: "Olivier Blanchet",
      //       role: "CEO",
      //       count: 5
      //     },
      //     {
      //       name: "Matt Michaud",
      //       role: "Chief Creative Officer",
      //       count: 2
      //     },
      //     {
      //       name: "Max Zalewski",
      //       role: "Chief Transformation Office",
      //       count: 2
      //     },
      //   ],
      //   courses: [
      //     {
      //       name: 'PRS IV Onboarding - history, culture, vision',
      //       time: 159,
      //       level: "Core",
      //       date_completed:"2023-01-05"
      //     },
      //     {
      //       name: 'PRS IV Onboarding - internal platforms & processes',
      //       time: 30,
      //       level: "Core",
      //       date_completed:"2024-02-01"
      //     },
      //     {
      //       name: 'PackLab methodology and analysis guideline​',
      //       time: 240,
      //       level: "Intermediate",
      //       date_completed:"2023-11-14"
      //     },
      //     {
      //       name: 'How to guide - qualitative​',
      //       time: 60,
      //       level: "Advanced",
      //       date_completed:"2024-01-05"
      //     },
      //   ]

      // })
        
      const levelColor = (level) => {
        if (level == 'CORE') return 'bronze'
        if (level == 'INTERMEDIATE') return 'silver'
        if (level == 'ADVANCED') return 'gold'
        return 'primary'
      }

      const availableTrainings = [
        {
          name: "Qualitative research fundamentals",
          level: "Core",
          instructor:"Direct Manager"
        },
        {
          name: "The life of a project",
          level: "Core",
          instructor:"Guillaume Chevalier"
        },
        {
          name: "Advanced statistical analysis​",
          level: "Intermediate",
          instructor:"Bénédicte & Lucile"
        },
      ]
      const trainingHeaders = [
        {
            text: 'Course Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Level', value: 'level' },
          { text: 'Instructors', value: 'instructor' }
      ]

      const courseHeaders = [
        {
            text: 'Course Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Level', value: 'level' },
          { text: 'Duration', value: 'duration' },
          { text: 'Completed Date', value: 'completed_date' },
      ]

      const resolveExpertiseColor = index => {
        const length = user.value.expertise.length
        
        if (index%length === 0) return 'primary'
        if (index%length === 1) return 'info'
        if (index%length === 2) return 'success'
        if (index%length === 3) return 'secondary'
        if (index%length === 4) return 'warning'
        if (index%length === 5) return 'accent'

        return 'primary'
      }

      const expertiseSeries = computed(() => {
        const data = user.value.expertise.map(expertise => {return {y: expertise.percent, x: expertise.name}})
        return [{
          data
        }]
      })

      
      const expertiseXAxis = computed(() => {
        return user.value.expertise.map(expertise => expertise.name)
        return [{
          data
        }]
      })

      const chartOptions = ref({
            chart: {
              type: 'bar',
              toolbar: {
                show: false
              }
            },
            grid: {
              padding: {
                left: 0,
                right: 0,
                top: -30,
                bottom: 0
              }
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                columnWidth: '40%',
                distributed: true,
                horizontal: true,
                borderRadius: 20,
                startingShape: 'rounded',
                dataLabels: {
                  position: 'bottom'
                }
              }
            },
            colors: [
            $vuetify.theme.currentTheme.primary,
            $vuetify.theme.currentTheme.info,
            $vuetify.theme.currentTheme.success,
            $vuetify.theme.currentTheme.secondary,
            $vuetify.theme.currentTheme.warning,
            $vuetify.theme.currentTheme.accent,
            $vuetify.theme.currentTheme.error,
            "#0F2F71",
            "#E74C3C",
            "#0EAC51",
            "#DA3C78",
            "#4CACC8",
            "#F1892D",
            "#7E349D",
            "#0077C0",
            "#07ABA0",
            "#FFBBD0",
            "#FFD966",
        ],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#fff']
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex]
              },
              offsetX: 0,
              dropShadow: {
                enabled: true
              }
            },
            xaxis: {
              type: 'category'
            },
            yaxis: {
              labels: {
                show: false
              }
            },
           
            legend: {
              show: false
            },
            stroke: {
              colors: ["transparent"],
              width: 20
            },
            tooltip: {
              theme: 'dark',
              x: {
                show: false
              },
              y: {
                title: {
                  formatter: function (val, series) {
                    return series.w.globals.labels[series.dataPointIndex]
                  }
                }
              }
            }
          })

      
      onMounted(() => {

        if (user.email) {
          fetchStudent(user.email)
          fetchStudentCompletedCourses(user.email)
        }
      })

      return {
        user,
        studentData,
        studentCoursesLoading,
        studentCompletedCourses,
        resolveExpertiseColor,
        expertiseSeries,
        chartOptions,
        courseHeaders,
        availableTrainings,
        trainingHeaders,

        getInitialName,
        stringToHslColor,
        levelColor,
        formatDuration,
        formatDateToMonthYearShort,

        icons: {
            mdiCheckDecagramOutline,
            mdiMedalOutline,
            mdiLaptop,
            mdiLightbulbOutline,
            mdiCalendarMultiselectOutline,
            mdiCalendarOutline,
            mdiClockOutline
        }
      }
        
    },
})
</script>



<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.img-top .v-image__image{
  
 background-position: center top !important;
}
</style>
