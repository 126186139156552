<template>
  <div class="user-tab-task">
    <v-navigation-drawer
      v-model="isEventHandlerDrawerActive"
      right
      touchless
      app
      temporary
      width="450"
    >
      <task-event-handler-drawer-content
        :task="task"
        :clear-event-data="clearTaskData"
        :taskLoading="taskLoading"
        :taskOptions="taskOptions"
        @add-task="addTask"
        @update-task="updateTask"
        @remove-task="removeTask"
        @close-drawer="isEventHandlerDrawerActive = false"
      ></task-event-handler-drawer-content>
    </v-navigation-drawer>


    <app-card-actions
     class="mb-7"
      no-actions
      :refresh="refreshTasks"
    >
      <template slot="title">
        <span>Tasks List</span>
        <v-spacer></v-spacer>
        <v-tooltip 
          v-for="filter in taskFilters"
          :key="filter.status"
          top
          :color="filter.color"
        >
          <template #activator="{ on, attrs }">
            <v-btn 
              icon
              small
              :color="filter.active ? filter.color : ''"
              class="me-1 mt-n2"
              v-bind="attrs"
              v-on="on"
              @click="filterStatus(filter)"
            >
              <v-icon >{{filter.icon}}</v-icon>
            </v-btn>
          </template>
          <span>{{filter.label}}</span>
        </v-tooltip>
      </template>
      
      <v-card-text>
        <v-radio-group
          v-model="sortList"
          row
          dense
          hide-details
          @change="updateSort"
          class="mt-0 pt-0 mb-2"
        >
          <v-radio
            label="Sort by Date"
            value="date"
          ></v-radio>
          <v-radio
            label="Sort by Project"
            value="project"
          ></v-radio>
        </v-radio-group>



        <v-list class="pt-0">
          <v-list-group 
              v-for="(status) in tasksList"
              :key="status.label"
              v-model="status.active"
              sub-group
              :append-icon="icons.mdiPlus">
            <template v-slot:activator>
              <v-list-item-title 
                class="font-weight-bold text-uppercase text-decoration-underline"
              >
                {{status.label}} ({{status.tasks.length}})
              </v-list-item-title>
              <v-list-item-action
                v-if="status.create"
                  class="my-0 "
              >
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      
                  <v-btn 
                  icon
                  @click.stop="createProjectTask(status.project)"
                  v-bind="attrs"
                  v-on="on"
                  >
                  <v-icon>{{icons.mdiPlus}}</v-icon> 
                </v-btn>
                      
                  </template>
                  <span>Add Task for {{status.label}}</span>
              </v-tooltip>
                
              </v-list-item-action>
            </template>
            <v-list-item
              v-for="(data, index) in status.tasks"
              :key="data.project? `s-${data.id}`: `p-${data.id}`"
              :class="`d-flex align-center task--${data.fill} rounded px-0 ${index > 0 ? 'mt-1':''}`"
              :disabled="data.project ? !data.editable : false"
              @click="clickTask(data)"
            >
              <div class="ml-2 d-flex align-center flex-grow-1 flex-wrap text-no-wrap">
                <v-col
                  cols="12"
                  md="5"
                >
                <div class="me-2">
                  <v-list-item-title class="text-sm font-weight-medium">
                    {{ data.name }}
                  </v-list-item-title>
                  <div class="d-flex align-center">
                    <v-icon
                      size="14"
                      class="me-1"
                    >
                      {{ icons.mdiCalendarBlankOutline }}
                    </v-icon>
                    <v-list-item-subtitle class="text-xs">
                      {{ formatDate(data.due_date_time) }}
                    </v-list-item-subtitle>
                  </div>
                </div>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                <div class="me-2">
                  <v-list-item-title class="text-sm font-weight-medium">
                    {{ data.type }}
                  </v-list-item-title>
                  <div class="d-flex align-center"
                    v-if="data.project">
                    <v-list-item-subtitle class="text-xs">
                      {{ data.project }} - {{ data.project_name }}
                    </v-list-item-subtitle>
                  </div>
                </div>
                </v-col>
                
                <v-col
                  cols="12"
                  md="2"
                >
                <v-chip
                  small
                  :color="data.fill"
                  :class="`v-chip-light-bg ${data.fill}--text font-weight-semibold right`"
                >
                  {{ data.statusText }}
                </v-chip>
                </v-col>
              </div>
            </v-list-item>
              <v-divider></v-divider>
          </v-list-group>
          
        </v-list>
      </v-card-text>
    </app-card-actions>
    
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, watch, getCurrentInstance, onMounted } from '@vue/composition-api'
import { mdiCalendarBlankOutline, mdiDotsVertical, mdiCheckCircle,
        mdiBriefcase, mdiClockTimeFive, mdiCalendarStart, mdiPlus } from '@mdi/js'
import { formatDate, stringToDate } from '@core/utils/filter'
import { groupBy, setDateSchedule } from '@core/utils'
import useUserView from '../useUserView'
import useTimeline from '@/views/apps/timeline/useTimeline'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import TaskEventHandlerDrawerContent from './TaskEventHandlerDrawerContent.vue'
import { foundryRequest } from '@/config/authConfig';
import store from '@/store'
import { formatTasks } from '@core/utils'

export default {
  components: {
    AppCardActions,
    TaskEventHandlerDrawerContent
  },
  props: {
    userData: {
      type: Object,
      required:true
    }
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const isEventHandlerDrawerActive = ref(false)
    const blankTask = {
      id: '',
      name: '',
      start_date_time: '',
      due_date_time: '',
      bucket: '',
      color: '',
      assignments: [],
      desc: '',
      editable:true
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))

    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const {
      taskLoading,
      taskOptions,
    } = useTimeline()

    
    const activeFilters = ref({
      complete: false,
      overdue: true,
      ongoing: true,
      notstarted: true
    })

    const taskFilters = ref([
      {status: 'complete', label: 'Complete', color: 'success', icon: mdiCheckCircle, active:false},
      {status: 'overdue', label: 'Overdue', color: 'error', icon: mdiBriefcase, active:true},
      {status: 'ongoing', label: 'On Going', color: 'accent', icon: mdiClockTimeFive, active:true},
      {status: 'notstarted', label: 'Not Started', color: 'primary', icon: mdiCalendarStart, active:true},

    ])

    const sortList = ref('date')
    const {
      refreshTasks,
      userTasks,
      fetchUserTasks,
      normalizeTask
    } = useUserView()

    const tasksList = ref([])

    const sortByDate = (tasks) => {
      tasks.map(task => {
        let taskDate = stringToDate(task.due_date_time)
        task.schedule = setDateSchedule(taskDate)
      })
      const groupedTasksByDate = groupBy(tasks, 'schedule')
      tasksList.value = []
      if ('Overdue' in groupedTasksByDate) tasksList.value.push({label: 'Overdue', tasks: groupedTasksByDate['Overdue'], active:true})
      if ('Today' in groupedTasksByDate) tasksList.value.push({label: 'Today', tasks: groupedTasksByDate['Today'], active:true})
      if ('This Week' in groupedTasksByDate) tasksList.value.push({label: 'This Week', tasks: groupedTasksByDate['This Week'], active:true})
      if ('Next Week' in groupedTasksByDate) tasksList.value.push({label: 'Next Week', tasks: groupedTasksByDate['Next Week'], active:true})
      if ('Later' in groupedTasksByDate) tasksList.value.push({label: 'Later', tasks: groupedTasksByDate['Later'], active:true})
      
    }

    const sortByProject = (tasks) => {
      
      const groupedTasksByProject = groupBy(tasks, 'project')
      const groupedList = [
        {label: props.userData.name, tasks: groupedTasksByProject['undefined'], active:true, create:true, project:null}
      ]
      delete groupedTasksByProject['undefined']

      for(let [key, value] of Object.entries(groupedTasksByProject))
      {
        groupedList.push({label: key, tasks: value, active:true, create:true, project:key})
      }
      tasksList.value = groupedList

    }

    const updateSort = (evt) => {
      const tasks = filterTasks()
      
      if (evt === 'date') sortByDate(tasks)
      if (evt === 'project') sortByProject(tasks)
      
    }

    const filterTasks = () => {
      const tasks = JSON.parse(JSON.stringify(userTasks.value))
      var list = []

      tasks.map( task => {
        taskFilters.value.map(filter => {
          if (filter.active && filter.status === task.status){
            list.push(task)
          }
        })

      })

      return list
    }

    const filterStatus = (status=null) => {
      if (status){
        status.active = !status.active
      }
      
      updateSort(sortList.value, filterTasks())
    }

    const clickTask = (event) => {
      task.value = event
      isEventHandlerDrawerActive.value = true
    }
    

  const fetchProjectTasks = async () => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    taskLoading.value = true
    store
      .dispatch(`app-project/fetchProjectTasks`)
      .then(response => {
        taskOptions.value = formatTasks(response.data)

      })
      .catch(error => {
        store.commit('comp-alert/SET_MESSAGE', error.response.data)
      })
    
    taskLoading.value = false
  }

  const fetchPersonalBuckets = async () => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch(`app-timeline/fetchPersonalBuckets`)
        .then(response => {
          store.commit(`app-timeline/SET_CALENDARS`, response.data)
        })
        .catch(error => {
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
        })
    }

    const createProjectTask = (jobNumber=null) => {
      
      clearTaskData()
      task.value.project = jobNumber
      isEventHandlerDrawerActive.value = true
    }

    const resetTaskDateFields = taskData => {
        if (taskData.start_date_time) taskData.start_date_time = new Date(taskData.start_date_time).toISOString()
        if (taskData.due_date_time) taskData.due_date_time = new Date(taskData.due_date_time).toISOString()
        return taskData
    }

    const addTask = (taskData) => {
      
      const formattedTaskData = resetTaskDateFields(taskData)
      
      if (taskData.project) addProjectTask(formattedTaskData)
      else addPersonalTask(formattedTaskData)
    }

    const addProjectTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      /* eslint-enable */
      store.dispatch(`app-timeline/addProjectTask`, {task: taskData} ).then((response) => {
        // TODO: Next Update - Perform adding or refetching
        const data = normalizeTask(response.data)
        userTasks.value.push(data)
        isEventHandlerDrawerActive.value = false
      })
      .catch(error => {
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
      })
    }

    const addPersonalTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      /* eslint-enable */
      store.dispatch(`app-timeline/addPersonalTask`, {task: taskData} ).then((response) => {
        // TODO: Next Update - Perform adding or refetching
        const data = normalizeTask(response.data)
        userTasks.value.push(data)
        isEventHandlerDrawerActive.value = false
      })
      .catch(error => {
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
      })
          
    }

    const updateTask = (taskData) => {
      taskData = resetTaskDateFields(taskData)
      if (taskData.project) updateProjectTask(taskData)
      else updatePersonalTask(taskData)
      
    }

    const updateProjectTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store.dispatch(`app-timeline/updateProjectTask`, {task: taskData} ).then(response => {
        const data = normalizeTask(response.data)
        updateTaskInUserTasks(data)
      })
    }

    const updatePersonalTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store.dispatch(`app-timeline/updatePersonalTask`, {task: taskData} ).then(response => {
        const data = normalizeTask(response.data)
        updateTaskInUserTasks(data)
        
      })
      .catch(error => {
        if(error.response){
          store.commit('comp-alert/SET_MESSAGE', error.response.data)}
      })
    }

    const updateTaskInUserTasks = (data) => {
      const index = userTasks.value.findIndex(task => {
        return task.id === data.id && task.type === data.type;
      })

      userTasks.value[index] = data
      updateSort(sortList.value)
    
      isEventHandlerDrawerActive.value = false
    }

    const removeTask = (taskData) => {
      if (taskData.project) removeProjectTask(taskData)
      else removePersonalTask(taskData)
    }

    const removeProjectTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store.dispatch(`app-timeline/removeProjectTask`, { id: taskData.id }).then(() => {
        removeTaskInUserTasks(taskData)
      })
      .catch(error => {
        if(error.response){
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
        }
      })
    }

    const removePersonalTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store.dispatch(`app-timeline/removePersonalTask`, { id: taskData.id }).then(() => {
        removeTaskInUserTasks(taskData)
      })
      .catch(error => {
        if(error.response){
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
        }
      })
    }

    const removeTaskInUserTasks = (data) => {
      const index = userTasks.value.findIndex(task => {
        return task.id === data.id && task.type === data.type;
      })
      userTasks.value.splice(index, 1)
      updateSort(sortList.value)
    
      isEventHandlerDrawerActive.value = false
    }

    watch(
      () => props.userData,
      () => {
        fetchUserTasks()
        filterStatus()
      }
    )


    watch(
      userTasks,
      () => {
        filterStatus()
      }
    )

    onMounted(() => {
      fetchPersonalBuckets()
      fetchProjectTasks()
    })

    return {
      isEventHandlerDrawerActive,
      task,
      clickTask,
      clearTaskData,
      formatDate,
      tasksList,
      sortList,
      updateSort,
      refreshTasks,
      filterStatus,
      taskFilters,
      createProjectTask,
      icons: {
        mdiDotsVertical,
        mdiCheckCircle,
        mdiCalendarBlankOutline,
        mdiBriefcase,
        mdiClockTimeFive,
        mdiCalendarStart,
        mdiPlus,
      },
      addTask,
      updateTask,
      removeTask,

      
      taskLoading,
      taskOptions
    }
  }
}
</script>

<st
