<template>
<app-card-actions
     class="mb-7"
      no-actions
      :refresh="refreshServTypeSummary"
    >
    <template slot="title">
      <span>Service Type Summary</span>
      
    </template>
  
    <v-card-text>
      <div
        v-for="(data,index) in userServTypeSummary"
        :key="data.title"
        :class="`statistics-table d-flex align-center pt-3 ${index > 0 ? 'mt-3':''}`" 
      >
        <v-badge
          :color="data.color"
          dot
        >
        </v-badge>
        <span class="ms-4">{{ data.title }}</span>
        <v-spacer></v-spacer>
        <span class="font-weight-semibold text--primary me-6">{{ data.count }}</span>
      </div>
    </v-card-text>
  </app-card-actions>
</template>

<script>
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { ref, watch } from '@vue/composition-api'
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import useUserView from '../useUserView'

export default {
  components: {
    AppCardActions
  },
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {
      refreshServTypeSummary,
      fetchUserServTypeSummary,
      userServTypeSummary
    } = useUserView()
    
    watch(
      () => props.userData,
      () => {
        fetchUserServTypeSummary()
      }
    )

    return {
      refreshServTypeSummary,
      userServTypeSummary,
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}
</style>
